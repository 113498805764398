/* NavBar */

nav {
  display: flex;
  align-items: center;
  background-color: #21222a;
  height: 90px;
  padding: 30px 25px;
}
a {
  text-decoration: none;
  background-color: transparent;
}

.disabled {
  background-color: #707070;
  left: 2px;
}



/* Footer */
.clear { clear: both; height: 60px; }
